import { User, WorkspaceSubscription } from 'modules/api'
import { getCustomerPortalURL } from 'modules/monetization/components/UpsellModal/utils'

export const formatNameWithEmail = (name?: string, email?: string) => {
  return `${name ?? ''} ${email ? `(${email})` : ''}`
}

/** Checks if the user can manage the subscription */
export const getCanManageSubscription = (
  subscription?: WorkspaceSubscription,
  user?: User,
  changePlanEnabled?: boolean,
  canManageWorkspace?: boolean
) =>
  changePlanEnabled
    ? canManageWorkspace
    : subscription?.createdByUser?.id === user?.id

/**
 * Gets the URL for managing the subscription, or null if user can't manage it
 */
export const getManageSubscriptionUrl = (
  subscription?: WorkspaceSubscription,
  user?: User
): string | null => {
  if (!subscription || !user) {
    return null
  }

  return getCanManageSubscription(subscription, user)
    ? getCustomerPortalURL()
    : null
}

/**
 * Gets the renewal date of the subscription, or an empty string
 * if not available
 */
export const getRenewalDate = (subscription: WorkspaceSubscription) =>
  subscription?.nextBillingTime || ''

/** Checks if a cancellation has been requested for the subscription */
export const isCancellationRequested = (subscription?: WorkspaceSubscription) =>
  Boolean(subscription?.cancellationRequestedTime || false)

/**
 * Gets the start date of the subscription, or an empty string
 * if not available
 */
export const getStartDate = (subscription: WorkspaceSubscription) =>
  subscription?.createdTime || ''

/**
 * Checks if the workspace is on a free plan or has a paid subscription
 */
export const getIsPaid = (subscription?: WorkspaceSubscription) =>
  Boolean(subscription?.products?.length)

/** Checks if the subscription is expired */
export const getIsExpired = (subscription?: WorkspaceSubscription) =>
  Boolean(subscription?.expirationTime || false) &&
  new Date() > new Date(subscription?.expirationTime)

/** Gets the name and email of the user who created the subscription */
export const getCreatorNameAndEmail = (
  subscription?: WorkspaceSubscription
): string => {
  if (!subscription?.createdByUser) {
    return ''
  }

  const { displayName, email } = subscription.createdByUser
  return `${displayName} (${email})`
}

/**
 * Calculates the number of added seats based on the subscribed product and
 * current member count
 */
export const getAddedSeats = (
  subscription?: WorkspaceSubscription,
  memberCount?: number
): number | undefined => {
  if (!subscription || !memberCount) {
    return undefined
  }

  const subscribedProduct = subscription.products?.[0]
  return subscribedProduct?.units
    ? memberCount - subscribedProduct.units
    : undefined
}
