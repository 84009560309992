import { CookieConsentScript } from 'modules/marketing_pages/components/Compliance/CookieConsent'

import { GoogleTagManagerBodyCode } from './GoogleTagManagerBodyCode'
import { GoogleTagManagerHeadCode } from './GoogleTagManagerHeadCode'

export const GoogleTagManager = () => {
  return (
    <>
      <GoogleTagManagerHeadCode />
      <GoogleTagManagerBodyCode />
    </>
  )
}

export function withGoogleTagManager<T extends Record<string, any>>(
  Component: React.FC<T>,
  renderCookieConsent = true
) {
  const WithGoogleTagManagerComponent = (props: T) => (
    <>
      {renderCookieConsent && <CookieConsentScript />}
      <GoogleTagManager />
      <Component {...props} />
    </>
  )
  return WithGoogleTagManagerComponent
}
