/**
 * This file contains all the nouns used in the app that we wish to exclude from translations
 */

export const GAMMA_PRO_PROPER_NOUN = 'Gamma Pro'
export const GAMMA_PROPER_NOUN = 'Gamma'
export const GAMMA_ARTIFACT_PROPER_NOUN = 'gamma'
export const GAMMA_ARTIFACT_PROPER_NOUN_PLURAL = 'gammas'
export const GRANT_PROPER_NOUN = 'Grant'
export const PRO_PROPER_NOUN = 'Pro'
export const PLUS_PROPER_NOUN = 'Plus'
export const FREE_PROPER_NOUN = 'Free'
