import { useRouter } from 'next/router'
import { useEffect, useCallback } from 'react'

import {
  DEFAULT_GAMMA_LOCALE,
  PSEUDO_LOCALE_KEY,
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'
import { dynamicLoadAndActivate } from 'modules/i18n/load'
import { getStore, useAppDispatch, useAppSelector } from 'modules/redux'
import { selectUserLocale } from 'modules/user/reducer'
import {
  safeGetLocaleStorageValue,
  useLocalStorage,
} from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import {
  selectPreviewLocale,
  selectPseudoLocaleActive,
  setDisplayLocale,
} from '../reducer'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from '../utils/localeKey'
import { useLinguiPseudoLocaleSync } from './useLinguiPseudoLocale'

// Computes which locale should be used based on the user setting, the local storage cache,
// and the locale provided by the users navigator.
const getLocaleToUse = (
  userSettingLocaleKey?: string,
  localStorageLocale?: string
) => {
  const supportedLocaleKeyFromNavigatorLanguage =
    getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback() // Always a supported Gamma locale (falls back to english)
  const navigatorLanguageGA = isNavigatorLanguageGA()

  // The app always loads in english first
  const localeToLoad = userSettingLocaleKey
    ? userSettingLocaleKey // 1. Prefer the actual user setting value
    : localStorageLocale
    ? localStorageLocale // 2. Then use the last known value
    : navigatorLanguageGA
    ? supportedLocaleKeyFromNavigatorLanguage // 3. Then use the browser language (if supported)
    : DEFAULT_GAMMA_LOCALE // 4. Finally, default to english

  return localeToLoad as SupportedLocaleKey
}

/**
 * Gets the locale based on
 * 1. user settings
 * 2. local storage
 * 3. navigator language
 * 4. default to english
 *
 * if this is called before user settings are loaded then it will used
 * the cached local storage option
 */
export const getLocale = () => {
  const store = getStore()

  return getLocaleToUse(
    selectUserLocale(store.getState()),
    safeGetLocaleStorageValue<string | undefined>(
      USER_SETTINGS_CONSTANTS.gammaLocale,
      undefined
    )
  )
}

/**
 * Preview is actually the change lang picker but before save
 * pseudo -> where is that stored?
 */
export function useLinguiInit() {
  const dispatch = useAppDispatch()
  const { query } = useRouter()
  const { locale: _queryLocale } = query

  const queryLocale =
    SUPPORTED_LOCALE_KEYS.includes(_queryLocale as SupportedLocaleKey) &&
    _queryLocale
  useLinguiPseudoLocaleSync()

  const previewLocale = useAppSelector(selectPreviewLocale)
  const isPseudoLocaleActive = useAppSelector(selectPseudoLocaleActive)
  const userSettingLocale = useAppSelector(selectUserLocale)
  // The latest known value of your user setting locale
  const [_lsLocale, setLocalStorageLocale] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    null
  )

  const loadLocale = useCallback(
    (locale: SupportedLocaleKey) => {
      dynamicLoadAndActivate(locale)
      dispatch(setDisplayLocale({ locale }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (isPseudoLocaleActive) {
      // we dont use loadLocale here because the pseudo locale does not have a proper
      // display name, so it can't be set as the displayLocale
      dynamicLoadAndActivate(PSEUDO_LOCALE_KEY)
      return
    }

    if (queryLocale) {
      loadLocale(queryLocale as SupportedLocaleKey)
      return
    }

    if (previewLocale) {
      loadLocale(previewLocale)
      return
    }
    if (userSettingLocale) {
      loadLocale(userSettingLocale as SupportedLocaleKey)
      return
    }

    loadLocale(getLocale())
  }, [
    isPseudoLocaleActive,
    queryLocale,
    previewLocale,
    userSettingLocale,
    loadLocale,
  ])

  useEffect(() => {
    // Always mirror the query locale into localStorage
    if (queryLocale) {
      setLocalStorageLocale(queryLocale as SupportedLocaleKey)
    }
  }, [setLocalStorageLocale, queryLocale])

  useEffect(() => {
    // Always mirror the users.settings.locale into localStorage
    if (userSettingLocale) {
      setLocalStorageLocale(userSettingLocale)
    }
  }, [setLocalStorageLocale, userSettingLocale])
}
