import { useAppSelector } from 'modules/redux'

import { SupportedLocaleKey } from '../constants'
import { selectDisplayLocale } from '../reducer'

// The lingui locale is always the source of truth
// for what language is currently being used.
export const useLinguiLocale = (): SupportedLocaleKey => {
  return useAppSelector(selectDisplayLocale)
}
