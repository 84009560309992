import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { selectPseudoLocaleActive, setPseudoLocaleActive } from '../reducer'

export function useLinguiPseudoLocaleSync() {
  const dispatch = useAppDispatch()
  const isPseudoLocaleActive = useAppSelector(selectPseudoLocaleActive)

  const [isActive, setActive] = useLocalStorage(
    USER_SETTINGS_CONSTANTS.isPseudoLocaleActive,
    false
  )

  // Initialize from LS
  useEffect(() => {
    if (isActive) {
      dispatch(setPseudoLocaleActive({ isActive }))
    }
  }, [dispatch])

  // Keep LS in sync with app state
  useEffect(() => {
    setActive(isPseudoLocaleActive)
  }, [setActive, isPseudoLocaleActive])
}
