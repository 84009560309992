import { useCallback, useEffect } from 'react'

import { useAppDispatch } from 'modules/redux'
import { getExistingQueryParams } from 'utils/url'

import { setIsIntercomOpen } from './reducer'

const GET_SUPPORT_QUERY_PARAM = 'get-support'

export const useLaunchIntercom = (
  {
    initializeViaQuery,
  }: {
    initializeViaQuery: boolean
  } = { initializeViaQuery: false }
) => {
  const dispatch = useAppDispatch()

  const launch = useCallback(() => {
    const Intercom = window.Intercom
    if (!Intercom) return

    Intercom('show')

    // We need to do this owing to https://github.com/gamma-app/gamma/blob/2db66fb0a2854a5bd0dfa990df88352c3c2b5c92/packages/client/src/modules/intercom/IntercomScript.tsx#L31-L35
    dispatch(setIsIntercomOpen({ isOpen: true }))
  }, [dispatch])

  useEffect(() => {
    if (!initializeViaQuery) return
    const launchSupport =
      getExistingQueryParams()[GET_SUPPORT_QUERY_PARAM] === 'true'
    if (launchSupport) {
      launch()
    }
  }, [initializeViaQuery, launch])

  return launch
}
