import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'

import { LocalePickerList } from 'modules/i18n/components/LocalePickerList'
import {
  SUPPORTED_LOCALE_OPTIONS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'

export const LocalePickerButtonAndModal = ({
  onLocaleSelect,
  selectedLocaleKey,
  buttonProps,
  showLabelOnMobile = false,
}: {
  onLocaleSelect: (key: SupportedLocaleKey) => void
  selectedLocaleKey: SupportedLocaleKey
  buttonProps?: ButtonProps
  showLabelOnMobile?: boolean
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        w="fit-content"
        onClick={onOpen}
        display={{ base: showLabelOnMobile ? 'block' : 'none', md: 'block' }}
        leftIcon={
          <Box display="inline-block">
            <FontAwesomeIcon icon={faGlobe} />
          </Box>
        }
        variant="link"
        textDecoration="none !important"
        alignContent="center"
        lineHeight="base"
        size="sm"
        fontWeight={500}
        _hover={{
          textDecoration: 'none',
        }}
        {...buttonProps}
      >
        {SUPPORTED_LOCALE_OPTIONS[selectedLocaleKey].displayName}
      </Button>
      {/* Mobile button */}
      <IconButton
        onClick={onOpen}
        display={{ base: showLabelOnMobile ? 'none' : 'block', md: 'none' }}
        aria-label={t`Change language`}
        icon={<FontAwesomeIcon icon={faGlobe} />}
        variant="ghost"
        alignContent="center"
        size="md"
        fontWeight={500}
        {...buttonProps}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="xl"
          isCentered
          preserveScrollBarGap={false}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent m={4}>
            <ModalHeader
              textAlign="center"
              fontSize={{ base: 'xl', md: '3xl' }}
            >
              <Trans>Choose language</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LocalePickerList
                selectedLocale={SUPPORTED_LOCALE_OPTIONS[selectedLocaleKey]}
                onLocaleSelect={(lkey) => {
                  onLocaleSelect(lkey)
                  onClose()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
