import { i18n, Messages } from '@lingui/core'

import { DEFAULT_GAMMA_LOCALE } from './constants'

export const dynamicLoadAndActivate = (() => {
  let currentLocale: string | null = null

  return async (locale: string) => {
    let localeToUse = locale
    if (!locale) {
      console.warn('[i18n] Unexpected empty locale')
      localeToUse = DEFAULT_GAMMA_LOCALE
    }

    currentLocale = localeToUse

    try {
      const catalog: Messages = await import(
        `translations/${localeToUse}/messages`
      )

      // this prevents race conditions of two calls of dynamicLoadAndActivate
      // back to back.  only load and activate the most recent locale
      if (localeToUse === currentLocale) {
        i18n.load(localeToUse, catalog.messages)
        i18n.activate(localeToUse)
      }
    } catch (e) {
      console.error('[i18n] Error loading locale messages:', e.message)
    }
  }
})()
