type GammaTiming = {
  ssrHTMLLoaded: ReturnType<typeof performance.now>
  appMainBundleLoaded: ReturnType<typeof performance.now>
  domContentLoaded: ReturnType<typeof performance.now>
  firstContentfulPaint: ReturnType<typeof performance.now>
  documentReadyStateInteractive: ReturnType<typeof performance.now>
  documentReadyStateComplete: ReturnType<typeof performance.now>
  editorAnimationsInitialized: ReturnType<typeof performance.now>
}

declare global {
  interface Window {
    gammaTiming: GammaTiming
  }
}

export const recordSitePerformanceEvent = (key: keyof GammaTiming) => {
  if (typeof window === 'undefined') {
    return
  }

  window.gammaTiming = window.gammaTiming || {}
  window.gammaTiming[key] = performance.now()
}

export const reportSitePerformance = () => {
  if (typeof window === 'undefined') {
    return
  }

  // If document.readyState is not complete, wait for it to be
  if (document.readyState !== 'complete') {
    window.addEventListener('load', reportSitePerformance)
    return
  }
  window.removeEventListener('load', reportSitePerformance)

  // TODO: Send these timings to a

  // Log all the performance timings, sorted by time
  console.log(
    '%cPerformance Timings',
    'color: white; background-color: #6B46C1; padding: 5px; font-size: 1.5em;'
  )
  Object.entries(window.gammaTiming)
    .sort(([, a], [, b]) => a - b)
    .forEach(([key, value]) => {
      // Round each value to 2 decimal places
      value = Math.round(value * 100) / 100
      console.log(`${key}: ${value}ms`)
    })
  console.log(
    '%cPerformance Timings END',
    'color: white; background-color: #6B46C1; padding: 5px; font-size: 1.5em;'
  )
}
