import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { faCircleCheck as faCircleCheckDuotone } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import {
  FrequencyUnit,
  ProductPrice,
  useUpdateSubscriptionProductMutation,
} from 'modules/api/generated/graphql'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { useMonetizationContext } from 'modules/monetization/context'
import {
  computeSubscriptionChangeProductData,
  getProOrPlusSubscriptionProduct,
} from 'modules/monetization/utils'
import { useUserContext } from 'modules/user/context/UserContext'
import { Deferred } from 'utils/deferred'
import { wait } from 'utils/wait'

import { PaidProductKey } from '../types'
import { getProductDetails } from './UpsellModal/constants'

type ChangePlanStatus = 'not-started' | 'in-progress' | 'verifying' | 'complete'

type ConfirmChangePlanModalProps = {
  isOpen: boolean
  isChecked: boolean
  changePlanStatus: ChangePlanStatus
  newProduct: PaidProductKey | null
  newProductPrice: ProductPrice | null
  onClose: () => void
  onConfirmClick: () => void
  onCancelClick: () => void
  onCheckboxConfirm: () => void
}

export const useChangePlanModal = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [changePlanStatus, setChangePlanStatus] =
    useState<ChangePlanStatus>('not-started')
  const [productKey, setProductKey] = useState<PaidProductKey | null>(null)
  const [productPrice, setProductPrice] = useState<ProductPrice | null>(null)
  const [confirmDef, setConfirmDef] = useState<Deferred<boolean>>(
    new Deferred()
  )
  const { refetch: refetchUser } = useUserContext()
  const { subscription, refetchSubscription } = useMonetizationContext()
  const [updateSubscriptionProduct] = useUpdateSubscriptionProductMutation()

  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'confirm-change-plan-modal',
  })
  const toast = useToast()

  const onCheckboxConfirm = useCallback(
    () => setIsChecked((prevIsChecked) => !prevIsChecked),
    []
  )

  const onCloseClick = useCallback(() => {
    confirmDef.reject()
    setIsChecked(false)
    onClose()
  }, [onClose, confirmDef])

  const confirmUpgradedSubscription = useCallback(async () => {
    return new Promise<void>((resolve, reject) => {
      if (!refetchSubscription) {
        reject()
        return
      }
      let attempts = 0
      const subscriptionRefetch = setInterval(() => {
        attempts++
        refetchSubscription().then(({ data }) => {
          const product = getProOrPlusSubscriptionProduct(data?.subscription)
          if (product?.key === productKey) {
            clearInterval(subscriptionRefetch)
            resolve()
          } else if (attempts > 25) {
            clearInterval(subscriptionRefetch)
            toast({
              title: <Trans>Failed to update subscription</Trans>,
              status: 'error',
              duration: null,
            })
            reject()
          }
        })
      }, 500)
    })
  }, [productKey, refetchSubscription, toast])

  const onConfirmClick = useCallback(async () => {
    const subscriptionId = subscription?.id
    const productPriceId = productPrice?.id
    if (!subscriptionId || !productPriceId) {
      return
    }
    setChangePlanStatus('in-progress')

    try {
      await updateSubscriptionProduct({
        variables: {
          id: subscriptionId,
          productPriceId,
        },
      })
      setChangePlanStatus('verifying')
      const wait2Seconds = wait(2000)
      await confirmUpgradedSubscription()
      await refetchUser?.() // This gets the new workspace information as well
      setChangePlanStatus('complete')
      // Make sure we wait at least 2 seconds to show the success message
      await wait2Seconds

      confirmDef.resolve(true)
      onClose()
      setIsChecked(false)
    } catch (e) {
      console.error(e)
      toast({
        title: <Trans>Failed to update subscription</Trans>,
        status: 'error',
        duration: null,
      })
    }

    setChangePlanStatus('not-started')
  }, [
    subscription?.id,
    productPrice?.id,
    updateSubscriptionProduct,
    confirmUpgradedSubscription,
    refetchUser,
    confirmDef,
    onClose,
    toast,
  ])

  const showChangePlanModal = useCallback(
    ({
      productKey: _productKey,
      productPrice: _productPrice,
    }: {
      productKey: PaidProductKey
      productPrice: ProductPrice
    }) => {
      const def = new Deferred<boolean>()
      setConfirmDef(def)
      setProductKey(_productKey)
      setProductPrice(_productPrice)
      onOpen()

      return def.promise
    },
    [onOpen]
  )

  return {
    // This launches the modal with the given product key and price
    showChangePlanModal,

    // These props are all passed to the ConfirmChangePlanModal component
    isOpen,
    isChecked,
    changePlanStatus,
    newProduct: productKey,
    newProductPrice: productPrice,
    onClose: onCloseClick,
    onConfirmClick,
    onCancelClick: onCloseClick,
    onCheckboxConfirm,
  }
}

export const ConfirmChangePlanModal = ({
  isOpen,
  isChecked,
  changePlanStatus,
  newProduct,
  newProductPrice,
  onClose,
  onConfirmClick,
  onCancelClick,
  onCheckboxConfirm,
}: ConfirmChangePlanModalProps) => {
  const { subscription } = useMonetizationContext()
  const computeChangeFn = useCallback(
    () => computeSubscriptionChangeProductData(subscription, newProductPrice),
    [subscription, newProductPrice]
  )
  const {
    newTotalPrice,
    newPerSeatPrice,
    seatCount,
    proratedUpgradePrice,
    proratedDays,
  } = useLocalizedFunction(computeChangeFn, [subscription])
  const isUpgrade = newProduct === 'pro'

  if (!newProduct || !isOpen || !subscription || !isUpgrade) {
    return null
  }

  const productDisplay = getProductDetails()[newProduct].name
  const frequencyDisplay =
    newProductPrice?.frequencyUnit === FrequencyUnit.Month ? 'month' : 'year'

  const agreementText = isUpgrade ? (
    <Trans>
      I agree to the new price of{' '}
      <strong>
        {newPerSeatPrice} per user per {frequencyDisplay}
      </strong>
      {seatCount && seatCount > 1 ? (
        <Text as="span" fontStyle="italic">
          {` (${newTotalPrice} per ${frequencyDisplay} for ${seatCount} users) `}
        </Text>
      ) : (
        ' '
      )}
      to change my plan to {productDisplay}.
      <br />
    </Trans>
  ) : null

  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans comment="Upgrades plan to a higher tier">
            Upgrade to {productDisplay}
          </Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {['verifying', 'complete'].includes(changePlanStatus) ? (
            <ChangePlanStatus status={changePlanStatus} />
          ) : (
            <Stack spacing={4}>
              <Text as="span">
                {isUpgrade ? (
                  <Trans>
                    You will be charged {proratedUpgradePrice} today for the
                    remaining {proratedDays} days in your current billing cycle.
                  </Trans>
                ) : (
                  <Trans>
                    Your next billing cycle will reflect the price of{' '}
                    {productDisplay}.
                  </Trans>
                )}
              </Text>

              <Flex gap={3} alignItems={'start'}>
                <Checkbox
                  mt={1}
                  isChecked={isChecked}
                  onChange={onCheckboxConfirm}
                />
                <Text cursor="pointer" onClick={onCheckboxConfirm}>
                  {agreementText}
                </Text>
              </Flex>
              <Box
                fontSize={'sm'}
                bgColor={'gray.200'}
                p={4}
                borderRadius={'md'}
              >
                <Text>
                  <Trans>
                    Discounts and credits may apply. The exact prorated amount
                    will be calculated based on the remaining time in your
                    current subscription billing cycle.
                  </Trans>
                </Text>
              </Box>
            </Stack>
          )}
        </ModalBody>

        {['not-started', 'in-progress'].includes(changePlanStatus) && (
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                colorScheme="gray"
                onClick={onCancelClick}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="solid"
                onClick={onConfirmClick}
                isLoading={changePlanStatus === 'in-progress'}
                isDisabled={!isChecked}
              >
                <Trans>Upgrade to {productDisplay}</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

const ChangePlanStatus = ({ status }: { status: ChangePlanStatus }) => {
  return status === 'in-progress' ? (
    <VStack py={12} spacing={4}>
      <Spinner size="lg" />
      <Heading size="md">
        <Trans>Upgrade successful</Trans>
      </Heading>
      <Text>
        <Trans>Just a sec while we update up your subscription...</Trans>
      </Text>
    </VStack>
  ) : status === 'verifying' ? (
    <VStack py={8} spacing={10}>
      <Box color="green.500">
        <FontAwesomeIcon icon={faCircleCheckDuotone} size="4x" />
      </Box>
      <Heading size="md">
        <Trans>Success! Thank you for your purchase.</Trans>
      </Heading>
    </VStack>
  ) : null
}
