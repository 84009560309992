import { useUpdateEffect } from '@chakra-ui/react'
import Head from 'next/head'

import { config } from 'config'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { isRobot } from 'utils/deviceDetection'

// https://app.onetrust.com/cookies/script-integration/01919ef7-fdd5-72aa-8b3e-9a438906692f/instructions
const ONE_TRUST_SCRIPT_ID = '01919ef7-fdd5-72aa-8b3e-9a438906692f'
const DATA_DOMAIN = `${ONE_TRUST_SCRIPT_ID}${
  config.APPLICATION_ENVIRONMENT === 'production' ? '' : '-test'
}`

const autoblockSrc = `https://cdn.cookielaw.org/consent/${DATA_DOMAIN}/OtAutoBlock.js`
const stubSrc = `https://cdn.cookielaw.org/consent/${DATA_DOMAIN}/otSDKStub.js`

export const CookieConsentScript = () => {
  const locale = useLinguiLocale()
  const enabled = !isRobot()

  useUpdateEffect(() => {
    try {
      if (enabled) {
        window.OneTrust?.changeLanguage(locale)
      }
    } catch (e) {
      // noop
    }
  }, [enabled, locale])

  if (!enabled) {
    return null
  }

  return (
    <Head>
      <script id="ot-autoblock" src={autoblockSrc} />
      <script id="ot-script" src={stubSrc} data-domain-script={DATA_DOMAIN} />
      <script
        id="ot-anon"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { }`,
        }}
      ></script>
    </Head>
  )
}
