import { useLingui } from '@lingui/react'
import { DependencyList, useMemo } from 'react'

// A memoized version of fn, which uses the `useLingui` hook to subscribe to locale updates
// See https://lingui.dev/tutorials/react-patterns#element-attributes-and-string-only-translations
export function useLocalizedFunction<T>(
  fn: () => T,
  deps: DependencyList = []
) {
  const linguiCtx = useLingui()

  return useMemo(fn, [linguiCtx, fn, ...deps])
}
