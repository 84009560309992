import { isRobot } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'

// Code from https://tagmanaoger.google.com/#/container/accounts/6065997942/containers/98661839/workspaces/2/tags
// Paste this code immediately after the opening <body> tag:
const GTM_BODY_CODE = `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TGJXRPT"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`

export const GoogleTagManagerBodyCode = () => {
  const enabled = !isRobot() && !shouldUsePublishedVersion()

  if (!useSSRMounted() || !enabled) return null

  return <div dangerouslySetInnerHTML={{ __html: GTM_BODY_CODE }}></div>
}
