import { i18n } from '@lingui/core'

import { config } from 'config'
import { FrequencyUnit, ProductsQuery } from 'modules/api'

export const getCustomerPortalURL = () => {
  return `${config.API_HOST}/billing/portal`
}

export const getCheckoutRedirectUrl = (
  workspaceId: string,
  productPriceIds: string[]
) => {
  return `${
    config.API_HOST
  }/billing/checkout/${workspaceId}/?ids=${productPriceIds.join(',')}`
}

// Helper to popup a window in the center of the screen
// Inspired by https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export const popupCenter = ({
  url,
  title,
  w,
  h,
}: {
  url: string
  title: string
  w: number
  h: number
}) => {
  // Fixes dual-screen position
  const dualScreenLeft =
    //                                Most browsers       Firefox
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  // Make sure we dont take up the full screen on smaller devices
  const widthToUse = Math.min(w, width * 0.8)
  const heightToUse = Math.min(h, height * 0.8)

  const left = (width - widthToUse) / 2 + dualScreenLeft
  const top = (height - heightToUse) / 2 + dualScreenTop

  return window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${widthToUse}, 
    height=${heightToUse}, 
    top=${top}, 
    left=${left}
    `
  )
}

export const getProductPrices = (products: ProductsQuery['products']) => {
  const proProduct = products?.find((p) => p.key === 'pro')
  const proMonthlyProductPrice = proProduct?.prices?.find(
    (p) => p.frequencyUnit === FrequencyUnit.Month
  )
  const proYearlyProductPrice = proProduct?.prices?.find(
    (p) => p.frequencyUnit === FrequencyUnit.Year
  )

  const plusProduct = products?.find((p) => p.key === 'plus')
  const plusMonthlyProductPrice = plusProduct?.prices?.find(
    (p) => p.frequencyUnit === FrequencyUnit.Month
  )
  const plusYearlyProductPrice = plusProduct?.prices?.find(
    (p) => p.frequencyUnit === FrequencyUnit.Year
  )

  return {
    proMonthlyProductPrice,
    proYearlyProductPrice,
    plusMonthlyProductPrice,
    plusYearlyProductPrice,
  }
}

export const formatNumber = (num: number) => {
  return i18n.number(num, {
    style: 'percent',
    maximumFractionDigits: 0,
  })
}
