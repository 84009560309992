import Head from 'next/head'

import { isRobot } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'

// Code from https://tagmanager.google.com/#/container/accounts/6065997942/containers/98661839/workspaces/2/tags
// Paste this code as high in the <head> of the page as possible:
const GTM_HEAD_CODE = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TGJXRPT');
`

const GTM_ADWORDS_CODE = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'AW-11368900852');
`

export const GoogleTagManagerHeadCode = () => {
  const enabled = !isRobot() && !shouldUsePublishedVersion()

  if (!useSSRMounted() || !enabled) return null

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: GTM_ADWORDS_CODE }} />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-11368900852"
      ></script>
      <script dangerouslySetInnerHTML={{ __html: GTM_HEAD_CODE }} />
    </Head>
  )
}
